<template>
  <div
    id="contacto"
    class="primary my-12 d-flex flex-column flex-md-row pa-md-12 pa-3"
    style="gap: 24px"
    >
    <div
      :style="{ width: $vuetify.breakpoint.mdAndUp ? '40%' : '100%', height: $vuetify.breakpoint.mdAndUp ? '' : '500px' }"
      class="d-flex flex-column justify-space-between px-6 order-1 order-md-0"
      >
      <div
        >
        <img
          :src="require('@/assets/img/logo.png')"
          :width="$vuetify.breakpoint.mdAndUp ? '200px' : '140px'"
          height="auto"
          ></img>
      </div>

      <div
        class="background--text font-weight-medium text-h5"
      >
        Somos la única red <br/>
        social y comercial que <br/>
        elimina las fronteras <br/>
        en los negocios.
      </div>

      <div
        class="font-weight-bold accent--text text-h4"
        >
        <div>
          @
        </div>
        <div>
          Partners
        </div>
        <div
          class="mt-n3"
          >
          Fans
        </div>

        <div
          class="d-flex mt-6"
          style="gap: 12px"
          >
          <v-icon
            large
            color="accent"
            >
            mdi-facebook
          </v-icon>

          <v-icon
            large
            color="accent"
            >
            mdi-twitter
          </v-icon>

          <v-icon
            large
            color="accent"
            >
            mdi-instagram
          </v-icon>
        </div>
      </div>
    </div>

    <div
      style="gap: 24px"
      :style="{ width: $vuetify.breakpoint.mdAndUp ? '60%' : '100%' }"
      class="px-6 d-flex flex-md-row flex-column order-0 order-md-1 text-center"
      >
      <div
        class="text-center"
        >
        <img
          :src="require('@/assets/img/bell.png')"
          :width="$vuetify.breakpoint.mdAndUp ? '200px' : '140px'"
          height="auto"
          ></img>
      </div>

      <div
        style="flex: 1"
        >
        <div
          class="text-h2 font-weight-bold background--text"
          >
          ¡Hablemos!
        </div>

        <div
          class="mt-3 background--text text-h5 font-weight-medium"
          >
          <div
            v-if="userType == 'partner'"
            >
            Si estás interesado en ser Partner déjanos tu email y te avisamos para ser parte de esta comunidad.
          </div>

          <div
            v-else
            >
            Si quieres conocer todas las tiendas, déjanos tu email y te avisamos para ser parte de esta comunidad.
          </div>
        </div>

        <div
          class="mt-4"
          >
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
            >
            <v-text-field
              label="Nombre y apellido"
              class="rounded-lg"
              v-model="form.fullName"
              type="text"
              dark
              :rules="[ v => !!v || 'El campo es obligatorio']">
            </v-text-field>

            <v-text-field
              :label="$t('fields.email')"
              ref="email"
              class="rounded-lg"
              v-model="form.email"
              type="text"
              dark
              :rules="[ v => !!v || 'El campo es obligatorio']">
            </v-text-field>
          </v-form>

          <div
            class="text-right"
            >
            <v-btn
              color="accent"
              class="rounded-pill secondary--text"
              large
              @click="send"
              >
              Enviar
            </v-btn>
          </div>

          <v-snackbar
            color="success"
            v-model="success"
            timeout="4000"
            >
            <div
              class="d-flex flex-row justify-center align-center"
              >
              <v-icon class="mr-2">mdi-check</v-icon> ¡Mensaje enviado con éxito!
            </div>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      valid: true,
      success: false,
      form: {
        fullName: null,
        subject: null,
        email: null,
        message: null
      }
    }
  },

  props: {
    userType: {
      type: String,
      required: true
    }
  },

  methods: {
    send () {
      this.success = true
    }
  }
}
</script>
